/* eslint-disable */
import {
  MSGS,
  EVENT_CHANNEL,
  connect,
  connectWc,
  event,
  ready,
  getProvider,
  getWallet,
  getWalletAddress,
  getNetName,
  hasEns,
  Contract
} from './ethersConnect'

import {hexAddr,  hexAbi,
        hdrnAddr, hdrnAbi, 
        hsimAddr, hsimAbi,
        icsaAddr, icsaAbi,
        waatsaAddr, waatsaAbi
} from '../../constants'

export default {
  async connect(ctx) {
    try {
      const oldAddress = ctx.state.address
      const oldNetwork = ctx.state.network

      const provider = getProvider()
      if (!provider) throw new Error(MSGS.NOT_CONNECTED)

      const wallet = getWallet()
      if (!wallet) throw new Error(MSGS.NO_WALLET)
      const address = await getWalletAddress()
      const network = await getNetName()

      // contract constants
      const hex  = new Contract(hexAddr,  hexAbi, wallet)
      const hdrn = new Contract(hdrnAddr, hdrnAbi, wallet)
      const hsim = new Contract(hsimAddr, hsimAbi, wallet)
      const icsa = new Contract(icsaAddr, icsaAbi, wallet)
      const waatsa = new Contract(waatsaAddr, waatsaAbi, wallet)

      if (network !== oldNetwork || address !== oldAddress) {
        ctx.commit('provider', provider)
        ctx.commit('connected', true)
        ctx.commit('error', null)
        ctx.commit('wallet', wallet)
        ctx.commit('address', address)
        ctx.commit('user', address)
        ctx.commit('network', network)
        ctx.commit('hex', hex)
        ctx.commit('hdrn', hdrn)
        ctx.commit('hsim', hsim)
        ctx.commit('icsa', icsa)
        ctx.commit('waatsa', waatsa)

        // Other vuex stores can wait for this
        event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_READY)

        // now check for .eth address too
        if (await hasEns()) {
          console.log('Net supports ENS. Checking...')
          ctx.commit('ens', await provider.lookupAddress(address))
          if (ctx.state.ens) {
            ctx.commit('user', ctx.state.ens)
          }
        }
      }
    } catch (err) {
      ctx.dispatch('disconnect', err)
    }
  },
  async disconnect(ctx, err) {
    ctx.commit('provider', null)
    ctx.commit('connected', false)
    ctx.commit('error', err)
    ctx.commit('wallet', null)
    ctx.commit('address', '')
    ctx.commit('user', '')
    ctx.commit('network', '')
    ctx.commit('ens', null)
    ctx.commit('hex', null)
    ctx.commit('hdrn', null)
    ctx.commit('hsim', null)
    ctx.commit('icsa', null)
    ctx.commit('waatsa', null)
  },
  async logout(ctx) {
    ctx.commit('address', '')
    ctx.commit('user', '')
  },
  async notConnected(ctx) {
    ctx.commit('address', '')
    ctx.commit('user', '')
  },
  async init(ctx) {

    event.$on(EVENT_CHANNEL, async function (msg) {
      console.log('Ethers event received', msg)
      switch (msg) {
        case MSGS.NOT_READY:
          await ctx.dispatch('disconnect')
          break
        case MSGS.NO_WALLET:
          await ctx.dispatch('logout')
          break
        case MSGS.ACCOUNT_CHANGED:
          await ctx.dispatch('connect')
          break
        case MSGS.NOT_CONNECTED:
          await ctx.dispatch('notConnected')
          break
      }
    })

    if (ready()) {
      await ctx.dispatch('connect')
      event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_INITIALIZED)
    } else {
      console.log('Log in to your Ethereum wallet to see what it can do!')
      // Usually should trigger connect on a user interaction as a best practice!
      // Replace this with a user button??
      await connect()
    }
    ctx.commit('initialized', true)
  },
  async manualConnect(ctx) {
    await connect()
  },
  async walletConnect(ctx) {
    await connectWc()
  }
}
