/* eslint-disable */
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = () => ({
  initialized: false,
  provider: null,
  connected: false,
  error: null,
  wallet: null,
  // user is ens or address
  user: '',
  address: '',
  network: '',
  ens: null,
  hex: null,
  hdrn: null,
  hsim: null,
  icsa: null,
  waatsa: null,
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
