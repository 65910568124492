/* eslint-disable */
export default {
  initialized: function (state, value) {
    state.initialized = value
  },
  provider: function (state, value) {
    state.provider = value
  },
  connected: function (state, value) {
    state.connected = value
  },
  error: function (state, value) {
    state.error = value
  },
  wallet: function (state, value) {
    state.wallet = value
  },
  user: function (state, value) {
    state.user = value
  },
  address: function (state, value) {
    state.address = value
  },
  network: function (state, value) {
    state.network = value
  },
  ens: function (state, value) {
    state.ens = value
  },
  hex: function (state, value) {
    state.hex = value
  },
  hdrn: function (state, value) {
    state.hdrn = value
  },
  hsim: function (state, value) {
    state.hsim = value
  },
  icsa: function (state, value) {
    state.icsa = value
  },
  waatsa: function (state, value) {
    state.waatsa = value
  }
}
